import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Util } from "./util";
import { Observable } from "../../node_modules/rxjs";

@Injectable()
export class CustomHttpclientService {
  constructor(private http: HttpClient) {}

  get(url, params: HttpParams): Observable<any> {
    let headers: HttpHeaders;
    headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Basic " + btoa("adm@email.com:adm")
    );
    return this.http.get(url, { headers: headers });
  }

  post(url, data, params: HttpParams): Observable<any> {
    let headers: HttpHeaders;
    headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Basic " + btoa("adm@email.com:adm")
    );

    headers = headers.append("Content-Type", "application/json");

    return this.http.post(url, JSON.stringify(data), {
      headers: headers,
      params: params
    });
  }
  


  put(url, data, params: HttpParams): Observable<any> {
    let headers: HttpHeaders;
    headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Basic " + btoa("adm@email.com:adm")
    );

    headers = headers.append("Content-Type", "application/json");

    return this.http.put(url, JSON.stringify(data), {
      headers: headers,
      params: params
    });
  }

  delete(url, params: HttpParams): Observable<any> {
    let headers: HttpHeaders;
    headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Basic " + btoa("adm@email.com:adm")
    );
    return this.http.delete(url, { headers: headers });
  }
}
