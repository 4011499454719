
import {  HttpEvent,  HttpHandler,  HttpInterceptor,  HttpRequest,  HttpXsrfTokenExtractor} from "../../node_modules/@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { browser } from "protractor";

@Injectable()
export class CustomInterceptorService implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const bearer: string = localStorage.getItem("docB");
    let requestToForward = req;
    return next.handle(requestToForward);
  }
}
