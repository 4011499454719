import { FormGroup } from '@angular/forms';

export class FormValidation {
  static isInvalid(group: FormGroup, control: string): boolean {
    return (
      group.get(control).invalid &&
      (group.get(control).touched || group.get(control).dirty)
    );
  }

  static isRequiredError(group: FormGroup, control: string): boolean {
    const errors = group.get(control).errors;
    if (errors) {
      return (
        FormValidation.isInvalid(group, control) &&
        Object.keys(errors)[0] === 'required'
      );
    }
    return false;
  }

  static isEmailError(group: FormGroup, control: string): boolean {
    const errors = group.get(control).errors;
    if (errors) {
      return (
        FormValidation.isInvalid(group, control) &&
        Object.keys(errors)[0] === 'email'
      );
    }
    return false;
  }

  static updateValidation(group: FormGroup) {
    Object.values(group.controls).forEach(control => {
      control.markAsTouched();
      control.updateValueAndValidity();
    });
  }
}
